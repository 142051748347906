html, body {
  max-width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  background-color: #ffffff;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
}

.title {
  font-size: 2.5em;
  text-decoration: underline;
}

.App .top {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -30px;
  border-right: .5px solid grey;
}


.App .top,
.App .bottom {
  height: 300px;
  width: 100%;
}

.App .top form {
  height: fit-content;
  background: white;
  border: 1px solid grey;
  border-radius: 4px;
  width: 300px;
  padding: 40px 10px;
  
}

.App .top form input {
  margin: 0 0 30px;
  width: 200px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 0.95em;
}

.App .top form .btn {
  height: 40px;
  width: 200px;
  background: white;
  border: 1px solid grey;
  cursor: pointer;
  border-radius: 4px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 0.95em;
}

.App .top form .btn:hover {
  color: white;
  background: grey;
}

.App .top form .btn:focus {
  box-shadow: none;
  outline: none;
}

.App .top form .btn.widget-btn {
  margin-top: 15px;
  background: #232ABE;
  border: 1px solid #232ABE;
  color: #FFFFFF;
}

.App .bottom hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #101357;
    padding: 0;
    width: 40%;
    margin: auto;

}

.App .top form .btn.widget-btn:hover {
  margin-top: 15px;
  background: #101357;
}

.App .bottom p {
  margin-top: 30px;
  color: #101357;
  font-size: 1.1em;
}

.sketch-1{
  display: block;
  max-width: 800px;
  max-height:500px;
  width: auto;
  height: auto;
  float: left;
  margin-left: 5%;
  /* margin-top: 10px; */
  border: 1px solid #101357;
}

.sketch-2{
  display: block;
  max-width: 800px;
  max-height:500px;
  width: auto;
  height: auto;
  float: right;
  margin-right: 5%;
  /* margin-top: 10px; */
  border: 1px solid #101357;
}

.item {
  text-align:center;
  display:block;
  background-color: transparent;
  border: 1px solid transparent;
  margin-right: 10px;
  margin-bottom: 1px;
  float:left;
  
}

.item2 {
  text-align:center;
  display:block;
  background-color: transparent;
  border: 1px solid transparent;
  margin-right: 10px;
  margin-bottom: 1px;
  float:right;
}

.download-link {
  text-decoration: none;
  background-color: #232ABE;
  border: 1px solid #232ABE;
  color: #FFFFFF;
  font-size: 1.2em;
  margin-top: 10px;
  padding: 7px;
  cursor: pointer;
  position: relative;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  display: none;  
}

.download-link2 {
  text-decoration: none;
  background-color: #232ABE;
  border: 1px solid #232ABE;
  color: #FFFFFF;
  font-size: 1.2em;
  margin-top: 10px;
  padding: 7px;
  cursor: pointer;
  position: relative;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  display: none;
}

.download-link:hover {
    background: #101357;
}

.loading {
  display: none;
}

@media (max-width: 1790px) {
  .sketch-1{
    max-width: 700px;
    max-height:400px;
  }
  
  .sketch-2{
    max-width: 700px;
    max-height:400px;
  }

  @media (max-width: 1690px) {
    .sketch-1{
      max-width: 675px;
      max-height:375px;
    }
    
    .sketch-2{
      max-width: 675px;
      max-height:375px;
    }
  }

  @media (max-width: 1620px) {
    .sketch-1{
      max-width: 650px;
      max-height:350px;
    }
    
    .sketch-2{
      max-width: 650px;
      max-height:350px;
    }
  }

  @media (max-width: 1534px) {
    .sketch-1{
      max-width: 620px;
      max-height:320px;
    }
    
    .sketch-2{
      max-width: 620px;
      max-height:320px;
    }
  }

  @media (max-width: 1428px) {
    .sketch-1{
      max-width: 600px;
      max-height:300px;
    }
    
    .sketch-2{
      max-width: 600px;
      max-height:300px;
    }
  }

  @media (max-width: 1360px) {
    .sketch-1{
      max-width: 590px;
      max-height:290px;
    }
    
    .sketch-2{
      max-width: 590px;
      max-height:290px;
    }
  }

  @media (max-width: 1321px) {
    .sketch-1{
      max-width: 585px;
      max-height:285px;
    }
    
    .sketch-2{
      max-width: 585px;
      max-height:285px;
    }
  }

  @media (max-width: 1300px) {
    .sketch-1{
      max-width: 565px;
      max-height:265px;
    }
    
    .sketch-2{
      max-width: 565px;
      max-height:265px;
    }
  }

  @media (max-width: 1205px) {
    .sketch-1{
      max-width: 550px;
      max-height:250px;
    }
    
    .sketch-2{
      max-width: 550px;
      max-height:250px;
    }
  }

  @media (max-width: 1180px) {
    .sketch-1{
      max-width: 530px;
      max-height:230px;
    }
    
    .sketch-2{
      max-width: 530px;
      max-height:230px;
    }
  }

  @media (max-width: 1100px) {
    .sketch-1{
      max-width: 520px;
      max-height:220px;
    }
    
    .sketch-2{
      max-width: 520px;
      max-height:220px;
    }
  }

  @media (max-width: 1074px) {
    .sketch-1{
      max-width: 510px;
      max-height:210px;
    }
    
    .sketch-2{
      max-width: 510px;
      max-height:210px;
    }
  }

  @media (max-width: 1035px) {
    .sketch-1{
      max-width: 500px;
      max-height:200px;
    }
    
    .sketch-2{
      max-width: 500px;
      max-height:200px;
    }
  }

  @media (max-width: 1002px) {
    .sketch-1{
      max-width: 485px;
      max-height:185px;
    }
    
    .sketch-2{
      max-width: 485px;
      max-height:185px;
    }
  }

 
  @media (max-width: 950px) {
    .item {
      margin-left: 25%;
    }

    .download-link {
      margin-right: 18%;
    }

    .download-link2 {
      margin-left: 20%;
    }
    
    .item2 {
      margin-right: 22% !important;
    }
  }

  @media (max-width: 725px) {
    .item {
      margin-left: 20%;
    }
    
    .item2 {
      margin-right: 18% !important;
    }
  }

  @media (max-width: 630px) {
    .item {
      margin-left: 18%;
    }
    
    .item2 {
      margin-right: 12% !important;
    }
  }

  @media (max-width: 590px) {
    .item {
      margin-left: 15%;
    }
    
    .item2 {
      margin-right: 7% !important;
    }

    .title {
      font-size: 2em;
    }
  }

  @media (max-width: 530px) {
    .item {
      margin-left: 12%;
    }
    
    .download-link {
      margin-right: 20%;
    }

    .item2 {
      margin-right: 2% !important;
    }

    .download-link2 {
      margin-left: 0%;
    }

    .App .top form { 
      width: 230px;
    }
  }

  @media (max-width: 414px) {
    .App .top form { 
      width: 200px;
    }

    .title {
      font-size: 1.7em;
      margin-top: 40px;
    }
  }

}